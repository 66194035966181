export const getPrompt = (topic: string, language: string) => {
  return {
    label: 'Origins of',
    topic: `The origins of "${topic}"`,
    description: `"Origins of" short-videos offer a fascinating way to explore the history and background of a wide range of topics. They can be used to uncover the stories and events that led to the creation of a particular thing or phenomenon, such as a holiday, a word, a custom, a product or even a brand. They provide a sense of context and understanding, making the topic more interesting and relevant. They can be used to entertain, educate and inform the audience. They can be used to show the evolution of the topic over time and how it has changed or impacted the world we know today. Whether you're a history buff looking to share your knowledge or a brand looking to create engaging and informative content, "Origins of" short-videos are a great choice.`,
    examples: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676949929/Woxo/Idea2video/category_details/origins_of/origin_of_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676949929/Woxo/Idea2video/category_details/origins_of/origin_of_1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676949929/Woxo/Idea2video/category_details/origins_of/origin_of_3.mp4'
    ],
    inspiration: [
      {
        url: 'https://www.youtube.com/@smarthistoryvideos',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676947016/Woxo/Idea2video/category_details/origins_of/AL5GRJW-dw94r86-W2w5Ctito9rKYEHaPE66fS69oMg6mQ_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Smarthistory',
        channel: '@smarthistoryvideos',
        subscribers: '276K',
        description: `A channel that provides animated explanations of art and cultural history, including the origins and evolution of various styles and movements.`
      },
      {
        url: 'https://www.youtube.com/@Biography',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676947058/Woxo/Idea2video/category_details/origins_of/AL5GRJU_R0fZmQ1WbEIdNDEIfTlvJg44I2B8T7hGtJSl_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Biography',
        channel: '@Biography',
        subscribers: '680K',
        description: `A channel that provides in-depth documentaries on the lives of famous historical figures and celebrities.`
      },
      {
        url: 'https://www.youtube.com/@RealStories',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676947096/Woxo/Idea2video/category_details/origins_of/c-7mUJoA0luUqhVob_BzCpIJCUXr0itPOiu7Qxen68zzfgdqcjZw6V26w3aZKtW0fcYH9NugDw_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Real Stories',
        channel: '@RealStories',
        subscribers: '5.91M',
        description: `A channel that provides documentaries and biographies on a wide range of topics, including historical figures, current events, and social issues.`
      }
    ],
    tier: 'premium',
    promptContent: `Write 1 short video script of 5 scenes, about the origins of ${topic} and return it as a VALID array of JSON objects (Language ${language})
   Example:
   [
    {
      "musicCategory": "Given the following Music Categories and a Topic give the one that match better with the Topic. Music Categories: [Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark,  Dramatic, Sad] Topic: ${topic}",          
      "scenes": [
        {
          "voiceover": "A hook similar but not the same to the following -> Did you know the origins of ${topic}?"
        },
        {
          "voiceover": "Write a short passage about the origins of ${topic}. E.g ${topic} was born in 1976 in New York... (max 30 words)"
        },
        {
          "voiceover": "Scene 2 continuation... (max 30 words)"
        },  
        {
          "voiceover": "Scene 3 continuation... (max 30 words)"
        },        
        {
          "voiceover": "A CTA to follow me for more"
        }
      ],
      "meta": {
        "title": "Video Title",
        "description": "A short description of the video for Youtube",
        "hashtags": "a list of hashtags separated by space related to the video",   
        "mediaDescription": "A description for a picture about the following topic (If the topic is a person a portrait). Topic: ${topic}"
      }
    }
   ]`
  };
};
