import chroma from 'chroma-js';
import {
  buildVideoSection,
  getErrorMessages,
  getVideosBySections,
  getVoiceOverMessages,
  getVoiceOvers,
  parseGPTAnswer,
  sendPrompt,
  updateVideosWithError,
  validateFulfilled
} from '../helpers/helper';
import { voices } from '../data/voices/voices';
import { getPhrasesAndWords } from '../data/prompts/preset/phrasesAndWords';
import { getWordByWord } from '../data/prompts/preset/wordByWord';

export const useTextStylesStore = (set, get) => ({
  // state
  font: 'boldify',
  type: 'stroke', // background | background-opacity | stroke | basic
  color: '#FFFFFF',
  hideText: false,
  preset: { label: 'Word by word', value: 'word_by_word' }, // typewriter | phrases_and_words | word_by_word
  textStyles: {
    top: 'bottom', // center | top | bottom
    textAlign: 'center', // center | left | right,
    fill: '#FFFFFF',
    backgroundColor: '#000000',
    customFont: {
      fontFamily: 'Montserrat-ExtraBold',
      path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
    },
    fontSize: '60',
    fontWeight: 600,
    stroke: '#000000',
    strokeWidth: 8,
    shadow: null,
    type: 'woxo-custom-text-basic',
    hide: false
  },

  // actions
  setFontAction: (t) => {
    set({
      font: t.value,
      textStyles: {
        ...get().textStyles,
        customFont: {
          fontFamily: t.fontFamily,
          path: t.path
        }
      }
    });

    get().updateTypesAndColors();
    get().setTextStyleAction();
  },

  setTypeAction: () => {
    if (get().font !== 'neon') {
      switch (get().type) {
        case 'basic':
          set({
            type: 'stroke',
            textStyles: {
              ...get().textStyles,
              type: 'woxo-custom-text-basic'
            }
          });
          break;
        case 'stroke':
          set({
            type: 'background',
            textStyles: {
              ...get().textStyles,
              type: 'woxo-custom-text-step-up'
            }
          });
          break;
        case 'background':
          set({
            type: 'background-opacity',
            textStyles: {
              ...get().textStyles,
              type: 'woxo-custom-text-step-up'
            }
          });
          break;
        default:
          set({
            type: 'basic',
            textStyles: {
              ...get().textStyles,
              type: 'woxo-custom-text-basic'
            }
          });
          break;
      }
      get().updateTypesAndColors();
      get().setTextStyleAction();
    }
  },

  setTextAlignAction: () => {
    switch (get().textStyles.textAlign) {
      case 'center':
        set({ textStyles: { ...get().textStyles, textAlign: 'left' } });
        break;
      case 'left':
        set({ textStyles: { ...get().textStyles, textAlign: 'right' } });
        break;
      default:
        set({ textStyles: { ...get().textStyles, textAlign: 'center' } });
        break;
    }
    get().setTextStyleAction();
  },

  setVerticalAlignAction: () => {
    switch (get().textStyles.top) {
      case 'center':
        set({ textStyles: { ...get().textStyles, top: 'bottom' } });
        break;
      case 'bottom':
        set({ textStyles: { ...get().textStyles, top: 'top' } });
        break;
      default:
        set({ textStyles: { ...get().textStyles, top: 'center' } });
        break;
    }
    get().setTextStyleAction();
  },

  setColorAction: (color) => {
    set({ color });
    get().updateTypesAndColors();
    get().setTextStyleAction();
  },

  updateTypesAndColors: () => {
    if (get().preset.value === 'typewriter') {
      // TYPE TEXT
      if (get().font === 'neon') {
        set({
          textStyles: {
            ...get().textStyles,
            type: 'woxo-custom-text-basic'
          }
        });
      } else {
        switch (get().type) {
          case 'basic':
          case 'stroke':
            set({
              textStyles: {
                ...get().textStyles,
                type: 'woxo-custom-text-basic'
              }
            });
            break;
          case 'background':
          case 'background-opacity':
            set({
              textStyles: {
                ...get().textStyles,
                type: 'woxo-custom-text-step-up'
              }
            });
            break;
        }
      }

      // COLORS
      if (get().font !== 'neon') {
        switch (get().type) {
          case 'stroke':
            set({
              textStyles: {
                ...get().textStyles,
                stroke: get().color === '#FFFFFF' ? '#000000' : '#FFFFFF',
                strokeWidth: 8,
                fill: get().color,
                backgroundColor: '',
                shadow: null
              }
            });
            break;
          case 'background':
            set({
              textStyles: {
                ...get().textStyles,
                backgroundColor: get().color === '#FFFFFF' ? '#FFFFFF' : get().color,
                fill: get().color === '#FFFFFF' ? '#000000' : '#FFFFFF',
                shadow: null,
                stroke: null,
                strokeWidth: null
              }
            });
            break;
          case 'background-opacity':
            set({
              textStyles: {
                ...get().textStyles,
                backgroundColor: chroma(get().color).alpha(0.5),
                fill: get().color === '#FFFFFF' ? '#000000' : '#FFFFFF',
                shadow: null,
                stroke: null,
                strokeWidth: null
              }
            });
            break;
          default:
            set({
              textStyles: {
                ...get().textStyles,
                backgroundColor: '',
                fill: get().color === '#FFFFFF' ? '#FFFFFF' : get().color,
                shadow: null,
                stroke: null,
                strokeWidth: null
              }
            });
            break;
        }
      } else {
        set({
          textStyles: {
            ...get().textStyles,
            fill: '#FFFFFF',
            backgroundColor: '',
            shadow: `${get().color} 0px 0px 16px`,
            stroke: null,
            strokeWidth: null
          }
        });
      }

      // FONTSIZE
      if (get().font === 'handwriting' || get().font === 'neon') {
        set({ textStyles: { ...get().textStyles, fontSize: 75 } });
      } else {
        set({ textStyles: { ...get().textStyles, fontSize: 60 } });
      }
    } else {
      set({
        textStyles: {
          ...get().textStyles,
          stroke: get().color === '#FFFFFF' ? '#000000' : '#FFFFFF',
          fill: get().color
        }
      });
    }
  },

  setTextStyleAction: () => {
    let _sections = [...get().sections];

    _sections.map((section, sectionIdx) => {
      if (section.errors?.length === 0) {
        let _currentSection = section;
        const _medias = section.medias;
        const _voiceoverAudio = section.voiceoverAudio;
        const _music = section.music;
        let _voiceover = section.voiceover;

        const _section = buildVideoSection(
          _currentSection,
          _voiceover,
          _medias,
          _voiceoverAudio,
          _music,
          get().videoProportion,
          get().preset.value,
          get().textStyles
        );

        _sections[sectionIdx] = _section;
        set(
          {
            sections: [..._sections],
            videosBySections: getVideosBySections(_sections)
          },
          false,
          'Update section - voice0ver'
        );
      }
    });

    set({ isOpenCustomize: false });
  },

  setHideTextAction: () => {
    set({ textStyles: { ...get().textStyles, hide: !get().textStyles.hide } });
    get().setTextStyleAction();
  },

  setPresetAction: async (preset) => {
    set({
      preset,
      isLoading: true,
      changeRunning: 'voiceover',
      voiceoverMessage: getVoiceOverMessages(),
      errorMessage: getErrorMessages(),
      isOpenCustomize: false
    });

    if (preset.value !== 'typewriter') {
      set({
        font: 'boldify',
        color: '#FFFFFF',
        textStyles: {
          ...get().textStyles,
          customFont: {
            path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf',
            fontFamily: 'Montserrat-ExtraBold'
          },
          stroke: '#000000',
          fill: '#FFFFFF',
          type: 'woxo-custom-text-basic'
        }
      });
    }

    let _sections = [...get().sections];
    Promise.allSettled(
      _sections.map((section, sectionIdx) => {
        return new Promise((resolve, reject) => {
          if (section.errors?.length === 0) {
            (async () => {
              _sections[sectionIdx].isLoading = true;
              set(
                {
                  sections: [..._sections],
                  videosBySections: getVideosBySections(_sections)
                },
                false,
                'Loading section'
              );

              // --- Captions
              let _captionsScenes = [];
              if (preset.value !== 'typewriter') {
                // Delete captions
                section.voiceover.forEach((v) => {
                  v.scenes.forEach((item) => {
                    if (item.captions) {
                      delete item.captions;
                    }
                  });
                });

                if (preset.value === 'phrases_and_words') {
                  const gptAnswerCaptions = await sendPrompt(
                    getPhrasesAndWords(JSON.stringify(section.voiceover))
                  );
                  _captionsScenes = parseGPTAnswer(gptAnswerCaptions);
                  // console.log('_captionsScenes', _captionsScenes);
                } else {
                  const gptAnswerCaptions = await sendPrompt(
                    getWordByWord(JSON.stringify(section.voiceover))
                  );
                  _captionsScenes = parseGPTAnswer(gptAnswerCaptions);
                  // console.log('_captionsScenes', _captionsScenes);
                }

                section.voiceover.map((v, idx) => {
                  v.scenes.map((s, i) => {
                    if (_captionsScenes[idx].scenes[i].captions)
                      s.captions = _captionsScenes[idx].scenes[i].captions;
                  });
                });
              }

              // VOICE-OVER
              const _voiceOvers = await getVoiceOvers(
                section.voiceover,
                get().voiceOver,
                voices,
                preset.value
              );
              const fulfilled = validateFulfilled(_voiceOvers);
              if (fulfilled) {
                let voiceOvers = [];
                _voiceOvers.forEach((m) => {
                  voiceOvers.push(m.value);
                });

                let _currentSection = section;
                const _medias = section.medias;
                const _voiceoverAudio = { disabled: get().voiceOver.disabled, audios: voiceOvers };
                const _music = section.music;
                let _voiceover = section.voiceover;

                const _section = buildVideoSection(
                  _currentSection,
                  _voiceover,
                  _medias,
                  _voiceoverAudio,
                  _music,
                  get().videoProportion,
                  get().preset.value,
                  get().textStyles
                );

                _sections[sectionIdx] = _section;
                set(
                  {
                    sections: [..._sections],
                    videosBySections: getVideosBySections(_sections)
                  },
                  false,
                  'Update section'
                );

                resolve(voiceOvers);
              } else {
                _sections[sectionIdx].errors.push({ error: 'An error' });
                _sections[sectionIdx].videos = updateVideosWithError(
                  [..._sections[sectionIdx].videos],
                  get().videoProportion
                );
                _sections[sectionIdx].isLoading = false;
                set(
                  {
                    sections: [..._sections],
                    videosBySections: getVideosBySections(_sections)
                  },
                  false,
                  'Error in voice over'
                );

                reject('An error');
              }
            })();
          } else {
            resolve();
          }
        });
      })
    ).then(() => {
      set({ isLoading: false, changeRunning: 'none' });
    });
  }
});
