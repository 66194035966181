import { upload } from '~/services/cloudinary.services';
import { clone, isUrl } from '~/helpers/javascript';
import axios from 'axios';
import { gtmFireEvent as trackingAction } from '~/helpers/gtm';

const processPreview = (url) => {
  const cover = url.replace(/\.(mp4|mkv|webm|mpeg|ogg|ogv|3gp|mpg|mov)$/, '.png');

  return cover.replace('/upload/', '/upload/q_10,f_auto/');
};

const extractName = (v) => {
  if (isUrl(v)) {
    const url = v.split('/');
    const name = url[url.length - 1].split('.')[0];
    return name;
  }
  return v;
};

const extractNameLeaveWords = (n) => {
  if (extractName(n)) {
    // const name = extractName(n).split('_-_')[1];
    const name = extractName(n);
    if (name !== undefined) {
      return name.replace(/_/g, ' ');
    }
    const nameAux = extractName(n).split('_');
    if (nameAux.length > 1) {
      return `${nameAux[0]} ${nameAux[1]}`;
    }
  }
  return extractName(n);
};

const findAll = async (query, page, limit) => {
  // TODO: Change api by env variable or for production api always
  return axios(`https://api-dev.woxo.tech/vod/galleryall`, {
    method: 'POST',
    data: { query, page, limit }
  })
    .then((res) => {
      return res.data.resources;
    })
    .catch((e) => {
      return e;
    });
};

const uploadMediasPromises = (local, handleUploadProgressAction, options) =>
  new Promise((resolve, reject) => {
    upload({
      file: local,
      options,
      onUploadProgress: (progressEvent) => {
        handleUploadProgressAction(progressEvent, local.name);
      }
    })
      .then((asset) => {
        resolve({ ...asset, name: local.name });
      })
      .catch((e) => {
        resolve(false);
        console.log(e);
      });

    trackingAction({
      event: 'onButtonClick',
      category: 'idea-to-video',
      action: 'upload-media'
    });
  });

export const useUploadStore = (set, get) => ({
  // state
  showUpload: false,
  uploadingProgress: {},
  medias: [],
  isLoadingMediaUploads: false,

  // actions
  setShowUploadAction: (sceneIdx) => {
    get().trackingAction({
      event: 'onButtonClick',
      category: 'idea-to-video',
      action: 'edit-media-video'
    });

    if (!get().user) {
      get().redirectSignUpAction();
    } else {
      set({ showUpload: true, sceneIdx: sceneIdx });
    }
  },

  setHideUploadAction: () => {
    set({ showUpload: false });
  },

  setDownloadPercentAction: (downloadPercent) => set({ downloadPercent }),

  handleUploadProgressAction: (progressEvent, fileName) => {
    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    set((state) => {
      let _progress = Object.assign({}, state.uploadingProgress);
      _progress[`${fileName}`] = percentCompleted;

      // console.log('_progress', _progress);
      return { uploadingProgress: _progress };
    });
  },

  uploadingVideosAction: (newMedias, handleUploadProgressAction, options) => {
    const promises = [];
    for (let index = 0; index < newMedias.length; index++) {
      const local = newMedias[index];
      promises.push(uploadMediasPromises(local, handleUploadProgressAction, options));
    }
    // console.log('promises', promises);
    Promise.all(promises).then((assets) => {
      // console.log('assets', assets);
      let _medias = clone(get().medias);
      _medias.map((m) => {
        const asset = assets.find((a) => a.name === m.path);
        if (asset)
          return Object.assign(m, {
            id: asset.asset_id,
            preview: asset.url,
            url: asset.url,
            status: 'uploaded',
            asset
          });
        return m;
      });

      set({ medias: _medias });
      // console.log('Upload when finish', _medias);
    });
  },

  uploadMediasAction: (newMedias) => {
    // console.log('_newMedias', newMedias);
    const _medias = [...newMedias, ...get().medias];
    set({ medias: _medias });

    const options = {
      folder: `woxo-videos-user-gallery/${
        get().user
          ? (get().plan?.userOwner && get().plan?.userOwner?.username) || get().user?.username
          : 'public'
      }`
    };

    get().uploadingVideosAction(newMedias, get().handleUploadProgressAction, options);
  },

  loadMediasAction: async () => {
    set({ isLoadingMediaUploads: true });

    let medias = [];
    const folder = `woxo-videos-user-gallery/${
      get().user
        ? (get().plan?.userOwner && get().plan?.userOwner?.username) || get().user?.username
        : 'public'
    }`;
    const query = `folder=${folder} AND resource_type=(image OR video)`;
    const found = await findAll(query, null, null);
    found.forEach((elem) => {
      let filename = extractNameLeaveWords(elem.filename ?? elem.original_filename).split(' ');
      filename.pop();

      Object.assign(elem, {
        type: elem.resource_type,
        preview: processPreview(elem.secure_url),
        url: elem.secure_url,
        id: elem.asset_id,
        filename: filename.join(' ')
      });
      medias.push(elem);
    });

    set({ medias: medias, isLoadingMediaUploads: false });
  },

  applyMediaAction: (media) => {
    let _video = { ...get().videoEdit };
    _video.scenes[get().sceneIdx].media = media.url;

    set({ videoEdit: _video, hasEdited: true, showUpload: false });
  }
});
