export const getPrompt = (topic: string, language: string) => {
  return {
    label: 'How to',
    topic: `How to "${topic}"`,
    description: `"How-to" short-videos are a great way to provide step-by-step instructions on a variety of topics, from cooking and crafting to home repairs and DIY projects. They offer a practical and hands-on approach to learning, allowing viewers to follow along and try the task themselves. They can be used to teach a wide range of skills, from basic to advanced, making them suitable for a wide range of audiences, from beginners to experts. Whether you're looking to teach a specific skill or just want to share your own personal tips and tricks, "How-to" short-videos are a great choice. They are perfect for social media, websites, and other digital channels, as they can be easily shared and viewed on a variety of devices.`,
    examples: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676945253/Woxo/Idea2video/category_details/how_to/how_to_1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676945253/Woxo/Idea2video/category_details/how_to/how_to_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676945253/Woxo/Idea2video/category_details/how_to/how_to_3.mp4'
    ],
    inspiration: [
      {
        url: 'https://www.youtube.com/@HowToCookThat',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676944608/Woxo/Idea2video/category_details/how_to/AL5GRJU-cYORq3Zumhx1cylgY6KgVX2NMjUZ5pNizxPfeg_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'How To Cook That ',
        channel: '@HowToCookThat',
        subscribers: '4.92M',
        description: `A channel that provides step-by-step tutorials for creating unique and delicious desserts.`
      },
      {
        url: 'https://www.youtube.com/@5MinuteCraftsFAMILY',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676944626/Woxo/Idea2video/category_details/how_to/1riWCu3OdjGFdUOtlB5d0xkQgQ1SjZKgx7629L85HmccWqvmfJkR_6lF1IpmeBiAOsuFe6jpaA_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: '5-Minute Crafts FAMILY',
        channel: '@5MinuteCraftsFAMILY',
        subscribers: '15.4M',
        description: `A channel that give you fun DIY projects, crafts and life hacks for the whole family. Experience the joy of doing it yourself!`
      },
      {
        url: 'https://www.youtube.com/@TheKingofRandom',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676944661/Woxo/Idea2video/category_details/how_to/1T067AXy174-ahl8b3xyMBRJn1k2b9HGmxzjP8842SiLjtULbYWl-0KEieRDdEnzEA359kAE_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'TKOR',
        channel: '@TheKingofRandom',
        subscribers: '12.5M',
        description: `A channel that provides tutorials on a wide range of topics, including science experiments, life hacks, and DIY projects.`
      }
    ],
    tier: 'premium',
    promptContent: `Write 1 short video script of 5 scenes, about How to ${topic}. Return it as a VALID array of JSON objects (Language ${language})
   Example:
   [
    {
      "musicCategory": "Given the following Music Categories and a Topic give the one that match better with the Topic. Music Categories: [Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark,  Dramatic, Sad] Topic: ${topic}",
      "scenes": [
        {
          "voiceover": "A hook similar to the following -> Do you know how to ${topic}?"
        },
        {
          "voiceover": "Brief explanation (max 30 words)"
        },
        {
          "voiceover": "Scene 2 continuation... (max 30 words)"
        },  
        {
          "voiceover": "Scene 3 continuation... (max 30 words)"
        },         
        {
          "voiceover": "A CTA to follow me for more"
        }
      ],
      "meta": {
        "title": "Video Title",
        "description": "A short description of the video for Youtube",
        "hashtags": "a list of hashtags separated by space related to the video",
        "mediaDescription": "A description for a picture about the following topic (If the topic is a person a portrait). Topic: ${topic}"
      }
    }
   ]`
  };
};
