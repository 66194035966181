export const getPrompt = (topic: string, language: string) => {
  return {
    label: 'Bedstory',
    topic: `A Bedstory about "${topic}"`,
    description: `Bed stories short-videos are the perfect way to unwind and relax before bedtime. They offer a captivating and soothing way to escape into a world of imagination and adventure. They can take the form of bedtime stories, fables, or even soothing guided meditations, depending on your target audience. They can be used to help children and adults alike fall asleep more easily, reducing stress and promoting a better night's sleep. Whether you're looking to create a brand for a sleep-related product or service, or simply want to offer a unique and calming experience to your audience, bed stories short-videos are a great choice.`,
    examples: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676931838/Woxo/Idea2video/category_details/a_bedstory/a_bedstory_3.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676931838/Woxo/Idea2video/category_details/a_bedstory/a_bedstory_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676931838/Woxo/Idea2video/category_details/a_bedstory/a_bedstory_1.mp4'
    ],
    inspiration: [
      {
        url: 'https://www.youtube.com/@ChuChuTVStorytime',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676926634/Woxo/Idea2video/category_details/a_bedstory/AL5GRJWCDKgcH1JIMoFnWDQq9e74EOrkv7GOZFxb4S3vNA_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'ChuChuTV Storytime for Kids',
        channel: '@ChuChuTVStorytime',
        subscribers: '2.85M',
        description: `A channel that features animated bedtime stories, nursery rhymes, and lullabies for kids.`
      },
      {
        url: 'https://www.youtube.com/@FairyTales.English',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676926664/Woxo/Idea2video/category_details/a_bedstory/_qr0MV0QyXVrIlOLrcpLtkMITfb9qJHLw53j7zPKPIS8326dNVSAkybFxDScQdSKBJ3W0lKM_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Fairy Tales and Stories for Kids',
        channel: '@FairyTales',
        subscribers: '1.92M',
        description: `A channel that features animated versions of classic fairy tales and bedtime stories for kids.`
      },
      {
        url: 'https://www.youtube.com/@EnglishFairyTales',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676926686/Woxo/Idea2video/category_details/a_bedstory/WF07uedZewNtguLWvTZvZwksj02txuOxzsFUPbgUDp3ilkZuaNUHu9z9XlgYF_bf8WfvRNgl1WA_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'EnglishFairyTales',
        channel: '@EnglishFairyTales',
        subscribers: '3.28M',
        description: `A channel that features animated bedtime stories, music videos, and educational content for kids.`
      }
    ],
    tier: 'free',
    promptContent: `Write 1 short video script of 5 scenes, with a bedstory for kids about ${topic} and return it as a VALID array of JSON objects (Language ${language})
    Example:
    [
     {
       "musicCategory": "Given the following Music Categories and a Topic give the one that match better with the Topic. Music Categories: [Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark,  Dramatic, Sad] Topic: ${topic}",          
       "scenes": [
        {
          "voiceover": "A hook similar but not the same to the following ->  Wanna hear an amazing story about ${topic}?"
        },
        {
        "voiceover": "Tell a bedstory for kids about ${topic} (max 30 words)"
        },
        {
          "voiceover": "Scene 2 continuation... (max 30 words)"
        },  
        {
          "voiceover": "Scene 3 continuation... (max 30 words)"
        },                 
        {
          "voiceover": "A CTA to follow me for more"
        }
       ],
       "meta": {
        "title": "Video Title",
        "description": "A short description of the video for Youtube",
        "hashtags": "a list of hashtags separated by space related to the video",   
        "mediaDescription": "A description for a picture about the following topic (If the topic is a person a portrait). Topic: ${topic}"
       }
     }
  ]`
  };
};
