export const getPrompt = (topic: string, language: string) => {
  return {
    label: 'What is?',
    topic: `What is "${topic}"`,
    description: `"What is" short-videos are a great way to introduce and explain a specific topic, person, or thing. They can be used to provide background information, historical context, and key facts about the subject. They can be used to educate and inform the audience, whether they are new to the topic or looking to learn more. They can be used to showcase a wide range of topics, from well-known historical figures to lesser-known but important people, places, or things. They can be used to explore the features, capabilities, and benefits of a specific product or service, or to explain complex scientific or technical concepts. Whether you're a teacher looking to provide information on a specific topic or a brand looking to create engaging and informative content, "What or Who is" short-videos are a great choice.`,
    examples: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676993527/Woxo/Idea2video/category_details/what_or_who_is/what_or_who_is__1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676993527/Woxo/Idea2video/category_details/what_or_who_is/what_or_who_is__2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676993527/Woxo/Idea2video/category_details/what_or_who_is/what_or_who_is__3.mp4'
    ],
    inspiration: [
      {
        url: 'https://www.youtube.com/@TEDEd',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676991136/Woxo/Idea2video/category_details/what_or_who_is/HjXKmDGoAp6b-JdGYpqJYYeN4S_CMpD_kqWvldpN53cbzn-i73t6mVNo8mNki-xLPwi0BVSFi-o_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'TED-Ed',
        channel: '@TEDEd',
        subscribers: '18.1M',
        description:
          'This channel features animated educational videos that explain complex ideas and subjects in an accesible and engaging way.'
      },
      {
        url: 'https://www.youtube.com/@kurzgesagt',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676991154/Woxo/Idea2video/category_details/what_or_who_is/AL5GRJWjS7at2j0n5yn5dsfYFe5x_V8QeN66ppWjzChpoQ_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Kurzgesagt – In a Nutshell',
        channel: '@kurzgesagt',
        subscribers: '20M',
        description:
          'This channel uses animations and motion graphics to explain a wide range of topics, from science and technology to social issues and current events.'
      },
      {
        url: 'https://www.youtube.com/@crashcourse',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676991170/Woxo/Idea2video/category_details/what_or_who_is/AL5GRJWgYIKGPmmX_9xIoaOh3eR1C5ibuTKvC1aDaJEcgg_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'CrashCourse',
        channel: '@crashcourse',
        subscribers: '14.4M',
        description:
          'This channel features educational videos that cover a wide range of topics, from history and science to literature and economics.'
      }
    ],
    tier: 'premium',
    promptContent: `Write 1 short video script of 5 scenes, about the definition of ${topic}. Return it as a VALID array of JSON objects (Language ${language})
   Example:
   [
    {
      "musicCategory": "Given the following Music Categories and a Topic give the one that match better with the Topic. Music Categories: [Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark,  Dramatic, Sad] Topic: ${topic}",          
      "scenes": [
        {
          "voiceover": "A hook similar but not the same to the following -> Do you know What or Who is ${topic}? (The definition is in the other scenes)"
        },
        {
          "voiceover": "Definition of ${topic} (max 30 words)"
        },
        {
          "voiceover": "Scene 2 continuation... (max 30 words)"
        },  
        {
          "voiceover": "Scene 3 continuation... (max 30 words)"
        },        
        {
          "voiceover": "A CTA to follow me for more"
        }
      ],
      "meta": {
        "title": "Video Title",
        "description": "A short description of the video for Youtube",
        "hashtags": "a list of hashtags separated by space related to the video",   
        "mediaDescription": "A description for a picture about the following topic (If the topic is a person a portrait). Topic: ${topic}" 
      }
    }
   ]`
  };
};
