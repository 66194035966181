export const getPrompt = (topic: string, language: string) => {
  return {
    label: 'Jokes',
    topic: `Jokes about "${topic}"`,
    description: `"Jokes" short-videos are a great way to entertain and make people laugh. They can take the form of stand-up comedy, comedic skits, or even animated cartoons. They can be used to provide a light-hearted and humorous break from the daily grind, and can be enjoyed by audiences of all ages. They can be used to showcase a wide range of comedic styles, from slapstick to observational humor, and can be tailored to different audiences and settings. Whether you're a comedian looking to showcase your talent or a brand looking to create engaging and funny content, "Jokes" short-videos are a great choice. Just keep in mind that humor can be subjective, and it's important to consider the target audience and the platform where the video will be shared.`,
    examples: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676946693/Woxo/Idea2video/category_details/jokes/jokes_1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676946693/Woxo/Idea2video/category_details/jokes/jokes_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676946693/Woxo/Idea2video/category_details/jokes/jokes_3.mp4'
    ],
    inspiration: [
      {
        url: 'https://www.youtube.com/@ComedyCentral',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676945522/Woxo/Idea2video/category_details/jokes/jzUQkiqje00yMdCaTwVauqclC67mNjSSA30Y9ILLsh7zIGOZYaCigN5L-sQCAp0vYuLlfVTiEws_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Comedy Central',
        channel: '@ComedyCentral',
        subscribers: '11.3M',
        description: `A channel that features a wide variety of comedic content, including stand-up comedy, sketches, and original series.`
      },
      {
        url: 'https://www.youtube.com/@FunnyOrDie',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676945547/Woxo/Idea2video/category_details/jokes/AL5GRJXYPMpfzNEbkvF_TWDSZuItkubTs6OvTDQKdgftzBU_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Funny Or Die',
        channel: '@FunnyOrDie',
        subscribers: '3.58M',
        description: `A channel that features original comedic content, including sketches, parodies, and short films.`
      },
      {
        url: 'https://www.youtube.com/@BadLipReading',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676945587/Woxo/Idea2video/category_details/jokes/AL5GRJWWyhM0qsTpRKws-yPDxZD3yNaMzKG_ZLiWYZGL_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Bad Lip Reading',
        channel: '@BadLipReading',
        subscribers: '8.07M',
        description: `A channel known for its comedic dubbing of popular videos and music with new, humorous dialogue.`
      }
    ],
    tier: 'free',
    promptContent: `Write 1 short video script of 5 scenes, with a Funny joke about ${topic} and return it as a VALID array of JSON objects (Language ${language})
    Example:
    [
     {
      "musicCategory": "Given the following Music Categories and a Topic give the one that match better with the Topic. Music Categories: [Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark,  Dramatic, Sad] Topic: ${topic}",  
       "scenes": [
         {
           "voiceover": "A hook similar but not the same to the following -> Want to laugh with this Joke about ${topic}? (The joke is in the other scene)"
         },
         {
           "voiceover": "The Joke question (max 30 words)"
         },
         {
          "voiceover": "Here keep the viewers wondering about what could be the joke answer E.g: So... do you know... (max 30 words)"
        },         
         {
          "voiceover": "The Joke answer (max 30 words)"
        },  
         {
           "voiceover": "A CTA to follow me for more"
         }
       ],
       "meta": {
        "title": "Video Title",
        "description": "A short description of the video for Youtube",
        "hashtags": "a list of hashtags separated by space related to the video",   
        "mediaDescription": "A description for a picture about the following topic (If the topic is a person a portrait). Topic: ${topic}"
       }
     }
    ]`
  };
};
