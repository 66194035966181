import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { gtmFireEvent } from '~/helpers/gtm';

import { initialData } from '../data/defaultDataSets/index';

import { data as languageData } from '../data/dataForLanguages';
import {
  voiceoverEN,
  voiceoverES,
  voiceoverFR,
  voiceoverHI,
  voiceoverDE,
  voiceoverPT
} from '../data/voices/voiceover';

import { db } from '../helpers/db';
import {
  updateProportionInVideos,
  randomIntFromInterval,
  getVideosBySections
} from '../helpers/helper';
import { getCredits } from '../helpers/credits.services';

import { prompts as categories } from '../data/prompts/index';
import { backgroundStyles } from '../data/dataForBackgroundStyles';

import { useDownloadStore } from './downloadStore';
import { useBackgroundStore } from './backgroundStore';
import { useTextStylesStore } from './textStylesStore';
import { usePreviewStore } from './previewStore';
import { useCategoriesStore } from './categoriesStore';
import { useVoiceOverStore } from './voiceOverStore';
import { useCreateStore } from './createStore';
import { useMusicStore } from './musicStore';
import { useEditStore } from './editStore';
import { useUploadStore } from './uploadStore';
import { useScheduleStore } from './scheduleStore';

export const useIdeaToVideoStore = create(
  devtools(
    subscribeWithSelector((set, get) => ({
      ...useDownloadStore(set, get),
      ...useBackgroundStore(set, get),
      ...useTextStylesStore(set, get),
      ...usePreviewStore(set, get),
      ...useCategoriesStore(set, get),
      ...useVoiceOverStore(set, get),
      ...useCreateStore(set, get),
      ...useMusicStore(set, get),
      ...useEditStore(set, get),
      ...useUploadStore(set, get),
      ...useScheduleStore(set, get),

      //states
      isLoading: false,
      sections: [],
      language: { value: 'EN', label: 'English' },
      prompt: '',
      changeRunning: 'none', // none | background | voiceover
      creationTime: '00:00',
      creationTimeCron: null,
      premiumModal: { type: 'voiceover', show: false }, // type: (voiceover | background | schedule)
      videosBySections: [],
      ideasForYou: [],
      errorMessage: '',
      isOpenCustomize: false,
      showTutorial: false,
      showFAQ: false,
      showNotLoggedIn: false,
      isLoadingAuth0: false,

      // credits
      credits: null, // user credits
      creationCosts: 10, // cost of creating 1 video
      editionCosts: 5, // cost of editing 1 video
      creditsCost: 0, // credits that would be used in the action
      showCredits: false,

      // User related
      showSignUpmodalAction: () => {},
      user: null,
      plan: null,

      //actions
      setupUserAction: (user) => {
        set({ user }, false, 'setup user action');
        get().setShowNotLoggedInAction(false);
      },

      setupPlanAction: (plan) => {
        set({ plan }, false, 'setup plan');
      },

      setupIsLoadingAuth0Action: (isLoadingAuth0) => {
        set({ isLoadingAuth0 }, false, 'setup isLoading Auth0');
      },

      setupCreditsAction: async (user, plan) => {
        const creditsRemaining = await getCredits(user._id, plan);
        set({ credits: creditsRemaining });
      },

      initAction: async (proportion) => {
        let _initialData = initialData[randomIntFromInterval(0, 0)];
        // if (proportion < 0.232) _initialData.data.length = 4;

        const _sections = updateProportionInVideos(proportion, _initialData.data);

        set(
          {
            sections: _sections,
            prompt: _initialData.prompt,
            videosBySections: getVideosBySections(_sections),
            videoProportion: proportion
          },
          false,
          'init'
        );

        get().restoreDownloadStateAction();

        get().setShowNotLoggedInAction(true);
      },

      setLanguageAction: async (language) => {
        set({ language }, false, ' set language');
      },

      startCreationTimeAction: () => {
        get().stopCreationTimeAction();
        const _creationTimeCron = setInterval(() => {
          get().setCreationTimeAction();
        }, 1000);
        set({ creationTimeCron: _creationTimeCron });
      },

      stopCreationTimeAction: () => {
        set({ creationTime: '00:00' });
        clearInterval(get().creationTimeCron);
      },

      setCreationTimeAction: () => {
        let creationTime = get().creationTime;
        creationTime = creationTime.split(':');
        let minute = Number(creationTime[0]);
        let second = Number(creationTime[1]);

        if (second < 60) {
          second++;
        }
        if (second === 60) {
          second = 0;
          minute++;
        }

        let time = '';

        if (minute < 10) time = '0' + minute;
        else time = minute;

        if (second < 10) time = time + ':0' + second;
        else time = time + ':' + second;

        let _sections = [...get().sections];
        _sections.map((s) => {
          s.creation.time = time;
        });

        set({
          sections: [..._sections],
          creationTime: time,
          videosBySections: getVideosBySections(_sections)
        });
      },

      setupShowSignUp: (showSignUp) => {
        set({ showSignUpmodalAction: showSignUp }, false, 'setup show signup');
      },

      restoreDownloadStateAction: async () => {
        const _videosToDownload = await db.videosToDownload.get(1);

        if (_videosToDownload) {
          set(
            { videosToDownload: [..._videosToDownload.videos] },
            false,
            'restore state (videos to download)'
          );

          if (get().videosToDownload.length !== 0) {
            let _videosToDownloadAux = get().videosToDownload;

            set(
              {
                videosToDownload: [..._videosToDownloadAux]
              },
              false,
              'Update videosToDownload'
            );
          }
        }
      },

      restoreStateAction: async (prevState) => {
        set({ sections: [], videosBySections: [] });
        set({ ...prevState }, false, 'restore state');
        set({ categories: [...categories] });
        get().restoreDownloadStateAction();
      },

      trackingAction: gtmFireEvent,

      setPremiumModalAction: (premiumModal) => {
        set({ premiumModal });
      },

      upgradePremiumModalAction: (type, fn) => {
        set({
          premiumModal: { type: get().premiumModal.type, show: false }
        });
        get().upgradePlanAction(type, fn);
      },

      setPromptAction: (prompt) => {
        set({ prompt });
      },

      setOpenCustomizeAction: (isOpenCustomize) => {
        set({ isOpenCustomize });
      },

      setShowTutorialAction: (showTutorial) => {
        set({ showTutorial });
        if (showTutorial) {
          get().trackingAction({
            event: 'onButtonClick',
            category: 'idea-to-video',
            action: 'show-tutorial'
          });
          setTimeout(() => {
            let body = document.querySelector('body');
            if (body) {
              body.style.overflowY = 'hidden';
            }
          });
        } else {
          let body = document.querySelector('body');
          if (body) {
            body.style.overflowY = 'scroll';
          }
        }
      },

      setShowCreditsAction: (showCredits) => {
        set({ showCredits });
        if (showCredits) {
          get().trackingAction({
            event: 'onButtonClick',
            category: 'idea-to-video',
            action: 'show-credits'
          });
          setTimeout(() => {
            let body = document.querySelector('body');
            if (body) {
              body.style.overflowY = 'hidden';
            }
          });
        } else {
          let body = document.querySelector('body');
          if (body) {
            body.style.overflowY = 'scroll';
          }
        }
      },

      buyCreditsAction: (fn) => {
        get().setShowCreditsAction(false);
        get().upgradePlanAction('credits', fn);
      },

      setIdeasForYouAction: (ideasForYou) => {
        set({ ideasForYou });
      },

      createIdeasForYouAction: (idea) => {
        get().applyCategoriesAction([...get().selectedCategories, idea]);

        get().trackingAction({
          event: 'onButtonClick',
          category: 'idea-to-video',
          action: 'idea-for-you',
          label: `${idea}`,
          params: {
            idea: `${idea}`,
            prompt: `${get().prompt}`
          }
        });
      },

      setShowFAQAction: (showFAQ) => {
        set({ showFAQ });
        if (showFAQ) {
          setTimeout(() => {
            let body = document.querySelector('body');
            if (body) {
              body.style.overflowY = 'hidden';
            }
          });
        } else {
          let body = document.querySelector('body');
          if (body) {
            body.style.overflowY = 'scroll';
          }
        }
      },

      setShowNotLoggedInAction: (showNotLoggedIn) => {
        set({ showNotLoggedIn });
        if (showNotLoggedIn) {
          setTimeout(() => {
            let body = document.querySelector('body');
            if (body) {
              body.style.overflowY = 'hidden';
            }
          });
        } else {
          let body = document.querySelector('body');
          if (body) {
            body.style.overflowY = 'scroll';
          }
        }
      },

      redirectNotLoggedInAction: (action) => {
        get().trackingAction({
          event: 'onButtonClick',
          category: 'idea-to-video',
          action: `user-without-${action}`
        });

        get().showSignUpmodalAction(window.location.pathname, action);
      },

      // ! INFO: action used in preview page of VidGPT plugin
      initializingPreviewVidGPTPluginStateAction: async (initialSections) => {
        let proportionPreview = Math.min(window.innerWidth / 1080, window.innerHeight / 1920);

        // Preview
        if (window.innerWidth < 962) {
          proportionPreview = proportionPreview - 0.0355;
        }
        if (window.innerWidth < 475) {
          proportionPreview = 0.3;
        }
        if (window.innerWidth < 390) {
          proportionPreview = 0.25;
        }

        const _sections = updateProportionInVideos(proportionPreview, initialSections);

        set(
          {
            sections: _sections,
            videosBySections: getVideosBySections(_sections),
            videoProportion: proportionPreview,
            categories: [...categories],
            background: {
              value: '',
              label: '',
              prompts: '',
              style_preset: ''
            }
          },
          false,
          'Initializing section'
        );

        const _video = initialSections[0].videos[0];
        _video.proportion = proportionPreview;
        const layer = { ..._video.clips[0]?.layers[0] };
        let media = layer.path;
        if (media && media.type !== 'woxo-image') {
          media = media.replaceAll('.mp4', '.jpg');
        }

        let _videoEdit = {
          id: _video.id,
          title: _video.meta.title,
          description: _video.meta.description,
          hashtags: _video.meta.hashtags,
          scenes: [],
          sectionIdx: 0,
          videoIdx: 0,
          idx: 0
        };

        const _videos = [...get().videosBySections];
        _videos.map((v) => {
          if (v.video.id === _videoEdit.id) {
            _videoEdit.sectionIdx = v.sectionIdx;
            _videoEdit.videoIdx = v.videoIdx;

            const _section = get().sections[v.sectionIdx];
            _section?.voiceover[v.videoIdx]?.scenes.forEach((s, sIdx) => {
              let _media =
                sIdx % 2 === 0 ? _section?.medias[v.videoIdx][0] : _section?.medias[v.videoIdx][1];

              _media =
                typeof _media === 'object'
                  ? URL.createObjectURL(_media)
                  : _media.replaceAll('.mp4', '.jpg');

              if (get().preset.value !== 'typewriter')
                _videoEdit.scenes.push({ media: _media, voiceover: s.captions });
              else _videoEdit.scenes.push({ media: _media, voiceover: s.voiceover });
            });
          }
        });

        let _voiceOptions = [...voiceoverEN];
        let _language = { value: 'EN', label: 'English' };

        languageData.map((l) => {
          if (l.value === _sections[0].languageCode) {
            _language = l;

            switch (l.value) {
              case 'ES':
                _voiceOptions = [...voiceoverES];
                break;
              case 'FR':
                _voiceOptions = [...voiceoverFR];
                break;
              case 'HI':
                _voiceOptions = [...voiceoverHI];
                break;
              case 'DE':
                _voiceOptions = [...voiceoverDE];
                break;
              case 'PT':
                _voiceOptions = [...voiceoverPT];
                break;
              default:
                _voiceOptions = [...voiceoverEN];
                break;
            }
          }
        });

        const _voiceover = {
          label: _voiceOptions[0].label,
          value: _voiceOptions[0].value,
          language: _language.value,
          style: _voiceOptions[0].styles[0].value,
          disabled: false
        };

        set({
          imagePreview: media,
          videoEdit: _videoEdit,
          prompt: _sections[0].topic,
          language: _language,
          voiceOver: _voiceover,
          voiceOptions: _voiceOptions
        });

        get().restoreDownloadStateAction();
      },

      // ! INFO: action used in preview page of VidGPT plugin
      goToIdeaToVideoAction: () => {
        // Proportion
        let proportion = 0.3;

        if (window.innerWidth < 1600) {
          proportion = 0.24;
        }
        if (window.innerWidth < 1440) {
          proportion = 0.28;
        }
        if (window.innerWidth < 1366) {
          proportion = 0.261;
        }
        if (window.innerWidth < 1280) {
          proportion = 0.232;
        }

        if (window.innerWidth < 428) {
          proportion = 0.178;
        }
        if (window.innerWidth < 414) {
          proportion = 0.172;
        }
        if (window.innerWidth < 390) {
          proportion = 0.161;
        }
        if (window.innerWidth < 375) {
          proportion = 0.154;
        }
        if (window.innerWidth < 360) {
          proportion = 0.147;
        }
        if (window.innerWidth < 350) {
          proportion = 0.144;
        }

        const _sections = updateProportionInVideos(proportion, get().sections);

        set(
          {
            sections: _sections,
            videosBySections: getVideosBySections(_sections),
            videoProportion: proportion
          },
          false,
          'Initializing section'
        );

        localStorage.setItem('ideaToVideoState', JSON.stringify(get()));

        setTimeout(() => {
          let body = document.querySelector('body');
          if (body) {
            body.style.overflowY = 'scroll';
          }
        });
      }
    }))
  )
);
