export const getPhrasesAndWords = (scenes: string) => {
  return `
  Split voiceovers by phrases( 6 words or so) with the character ~. On each split part highlight the main keywords of that piece with asterisks "*word*".

  Example Input:
  [
    {
      "scenes": [
    {
        "voiceover": "Welcome to my channel! Here's some fun facts about code."
    },
    {
        "voiceover": "Did you know that the first programming language was created in 1883?"
    },
    {
        "voiceover": "The world's first computer bug was discovered in 1947."
    },
    {
        "voiceover": "The most popular programming language in the world is JavaScript."
    },
    {
        "voiceover": "Stay tuned for more fun facts about code! See you next time."
    }
      ]
    }
  ]

  Example Output:
  [
    {
      "scenes": [
    {s
        "captions": "Welcome ~ to my *channel!* ~ Here's ~ some *fun facts* about ~ code"
    },
    {
        "captions": "Did you *know* ~ that ~ the first *programming language* ~ was ~ created in *1883?*"
    },
    {
        "captions": "The world's ~ first *computer bug* ~ was ~ *discovered* in 1947"
    },
    {
        "captions": "The *most* ~ *popular* programming language ~ in the *world* ~ is ~ *JavaScript*"
    },
    {
        "captions": "*Stay* tuned ~ for ~ more *fun facts* about code! ~ See you *next* time"
    }
      ]
    }
  ]

  Sentences: 
  ${scenes}
  
  Return it as a VALID array of JSON objects: [] 
  `;
};
