// Memo: These initial prompts were designed to match a 20s video lenght
// but to be honest the Storytelling sucked, so that's why we changed to
// more scenes

// import { getPrompt as aBedStoryAboutTopic } from './aBedStoryAboutTopic';
// import { getPrompt as aDayInTheLifeOfTopic } from './aDayInTheLifeOfTopic';
// import { getPrompt as anHorrorStoryAboutTopic } from './anHorrorStoryAboutTopic';
// import { getPrompt as funFacts } from './funFacts';
// import { getPrompt as hiddenSecrets } from './hiddenSecrets';
// import { getPrompt as howTo } from './howTo';
// import { getPrompt as jokes } from './jokes';
// import { getPrompt as originsOfTopic } from './originsOfTopic';
// import { getPrompt as quotes } from './quotes';
// import { getPrompt as tips } from './tips';
// import { getPrompt as topicFAQ } from './topicFAQ';
// import { getPrompt as whatIf } from './whatIf';
// import { getPrompt as whatIfTopicDissapears } from './whatIfTopicDissapears';
// import { getPrompt as whatOrWhoIs } from './whatOrWhoIs';

// Memo: These prompts offer a more complete storrytelling YEY!!!!

import { getPrompt as aBedStoryAboutTopic } from './5-scenes/aBedStoryAboutTopic';
import { getPrompt as aDayInTheLifeOfTopic } from './5-scenes/aDayInTheLifeOfTopic';
import { getPrompt as anHorrorStoryAboutTopic } from './5-scenes/anHorrorStoryAboutTopic';
import { getPrompt as funFacts } from './5-scenes/funFacts';
import { getPrompt as hiddenSecrets } from './5-scenes/hiddenSecrets';
import { getPrompt as howTo } from './5-scenes/howTo';
import { getPrompt as jokes } from './5-scenes/jokes';
import { getPrompt as originsOfTopic } from './5-scenes/originsOfTopic';
import { getPrompt as quotes } from './5-scenes/quotes';
import { getPrompt as tips } from './5-scenes/tips';
import { getPrompt as topicFAQ } from './5-scenes/topicFAQ';
import { getPrompt as whatIf } from './5-scenes/whatIf';
import { getPrompt as whatIfTopicDissapears } from './5-scenes/whatIfTopicDissapears';
import { getPrompt as whatOrWhoIs } from './5-scenes/whatOrWhoIs';
import { getPrompt as freeStyle } from './5-scenes/freeStyle';

export const prompts = [
  freeStyle,
  aBedStoryAboutTopic,
  aDayInTheLifeOfTopic,
  anHorrorStoryAboutTopic,
  funFacts,
  hiddenSecrets,
  howTo,
  jokes,
  originsOfTopic,
  quotes,
  tips,
  topicFAQ,
  whatIf,
  whatIfTopicDissapears,
  whatOrWhoIs
];
