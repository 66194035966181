export const getPrompt = (topic: string, language: string) => {
  return {
    label: 'Day in the Life',
    topic: `A Day in the Life of "${topic}"`,
    description: `"A day in the life" short-videos offer a unique and captivating way to showcase what it's like to live a certain lifestyle or experience a specific event. They can be used to give viewers a behind-the-scenes look at a specific profession, such as a day in the life of a doctor or a chef, or to document a day in the life of a person experiencing a unique event, such as a backpacker traveling to a remote location. These videos provide an authentic and relatable experience, giving the audience a chance to see the world from a different perspective and learn something new. Whether you're looking to promote a business, brand, or product, or simply want to document and share your own personal experiences, "A day in the life" short-videos are a great choice.`,
    examples: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676935998/Woxo/Idea2video/category_details/a_day_in_the_life/a_day_in_the_life_1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676935998/Woxo/Idea2video/category_details/a_day_in_the_life/a_day_in_the_life_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676935998/Woxo/Idea2video/category_details/a_day_in_the_life/a_day_in_the_life_3.mp4'
    ],
    inspiration: [
      {
        url: 'https://www.youtube.com/@casey',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676936034/Woxo/Idea2video/category_details/a_day_in_the_life/AL5GRJXcj97zkWMNabnC3OhGfYWdEwUDyWLDDWoU0IGdkw_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'CaseyNeistat',
        channel: '@casey',
        subscribers: '12.5M',
        description: `A filmmaker and vlogger who shares daily vlogs that document his daily activities, creative projects, and travels.`
      },
      {
        url: 'https://www.youtube.com/@MrBeast',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676936053/Woxo/Idea2video/category_details/a_day_in_the_life/AL5GRJV-BviaWnNvLLWoK18JTaIs6IC6adCul_JzqgQtkA_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'MrBeast',
        channel: '@MrBeast',
        subscribers: '130M',
        description: `A popular content creator who shares daily vlogs and videos that showcase his philanthropy, stunts, and challenges.`
      },
      {
        url: 'https://www.youtube.com/@MrKate',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676936069/Woxo/Idea2video/category_details/a_day_in_the_life/AL5GRJU1XkBMxNRI-YRCCKhqYIz-eWftTtJfKvVzBox8rQo_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Mr. Kate',
        channel: '@MrKate',
        subscribers: '3.92M',
        description: ` A lifestyle and DIY creator who shares daily vlogs, home renovation projects, and fashion tutorials.`
      }
    ],
    tier: 'free',
    promptContent: `Write 1 short video script of 5 scenes, about a day in life of ${topic} and return it as a VALID array of JSON objects (Language ${language})
  Example:
  [
   {
    "musicCategory": "Given the following Music Categories and a Topic give the one that match better with the Topic. Music Categories: [Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark,  Dramatic, Sad] Topic: ${topic}",
     "scenes": [
      {
        "voiceover": "A hook similar but not the same to the following ->  How does a day in the life of ${topic} look like?"
      },
      {
        "voiceover": "A brief description about some of the activities that ${topic} do during a regular day. E.g: He wake up early on and eat his lunch at 5pm (max 30 words)"
      },
      {
        "voiceover": "Scene 2 continuation... (max 30 words)"
      },  
      {
        "voiceover": "Scene 3 continuation... (max 30 words)"
      },        
      {
        "voiceover": "A CTA to follow me for more"
      }
     ],
     "meta": {
      "title": "Video Title",
      "description": "A short description of the video for Youtube",
      "hashtags": "a list of hashtags separated by space related to the video",   
      "mediaDescription": "A description for a picture about the following topic (If the topic is a person a portrait). Topic: ${topic}"  
     }
   }
]`
  };
};
